import { useCartStore } from '~/store/cart';
import { useGeodataStore } from '~/store/geodata';
import { getCookie, setCookie, deleteCookie, CookieKeys } from '~/util/storage';
import { sentryScopedException } from '~/util/error';
import { useCustomerStore } from '~/store/customer';

const soloUserCookie = CookieKeys.User;
const cookieExpDays = 7;
class CustomerException {
  name: string;
  message: string;
  level: string;

  constructor(message: string, level = 'log') {
    this.name = 'CustomerException';
    this.message = message;
    this.level = level;
  }
}

export async function useLogin(credentials: { email: string; password: string }) {
  const customerStore = useCustomerStore();
  const { regionHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
  };
  try {
    const config = useRuntimeConfig();
    const bcChannelId = config?.public?.bcChannelId;
    customerStore.setError('');
    const body = JSON.stringify({
      email: credentials.email,
      password: credentials.password,
      channel_id: parseInt(bcChannelId),
    });
    // TODO: May want enable legacy mode in useFetchApiLayer and
    // for more custom error handling. Right now, res is given the
    // payload for the data. If you need access to the original req,
    // add a third argument with the value of 'true', and access
    // the wanted payload with res._data
    const res = await useFetchApiLayer(`/customerLogin`, {
      method: 'POST',
      headers,
      body,
    });
    if (!res || !res.jwt) {
      throw new CustomerException(res?.message);
    }
    const domain = config?.public?.cookieDomain;

    customerStore.setLoading(true); // set here so when loggedIn = true and orders havent fetched, loading is displayed
    customerStore.setCustomer(res.customer);
    customerStore.setLoggedIn(res.jwt !== undefined);
    setCookie(soloUserCookie, res.jwt, cookieExpDays, true, domain);
    await useUpdateCartWithCustomerId(); // , { root: true }
    await useGetAllOrders();
  } catch (error: any) {
    useHandleCustomerError({ error });
    customerStore.setError(error?.message);
    Promise.resolve(error);
  }
}

export async function useCreateCustomer({ customerArray, redirectToAccount }: any) {
  const customerStore = useCustomerStore();
  const { regionHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
  };
  try {
    const localePath = useLocalePath();
    const data = await useFetchApiLayer(`/customerRegister`, {
      method: 'POST',
      headers,
      body: JSON.stringify(customerArray),
    });
    // console.log(JSON.stringify(customerArray))
    if (data.errors) {
      const errors = Object.values(data.errors).join(', ');
      customerStore.setError(errors);
    } else {
      if (customerArray?.length && data) {
        const customer = customerArray[0];
        await useLogin({
          email: customer?.email,
          password: customer?.authentication?.new_password,
        });
        customerStore.setLoginCred({
          email: customer?.email,
          password: customer?.password,
        });
      }
      if (redirectToAccount) {
        await navigateTo(localePath('/account'));
      }
    }
    await navigateTo(localePath('/account'));
  } catch (error: any) {
    useHandleCustomerError({ error });
    customerStore.setError(error?.message);
    Promise.resolve(error);
  }
}

export async function useLogout() {
  const customerStore = useCustomerStore();
  const config = useRuntimeConfig();
  const domain = config?.public?.cookieDomain;
  const { regionHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
  };
  try {
    const jwt = getCookie(soloUserCookie);
    await useFetchApiLayer(`/customerLogout`, {
      method: 'POST',
      headers,
      body: JSON.stringify(jwt),
    });
    customerStore.setLoggedIn(false);
    customerStore.setCustomer(null);
    customerStore.setOrders(null);
    deleteCookie(soloUserCookie, domain);
    const cartStore = useCartStore();
    await cartStore.deleteCart();
  } catch (error: any) {
    useHandleCustomerError({ error });
    customerStore.setError(error?.message);
    Promise.resolve(error);
  }
}

export async function useGetAllOrders() {
  const customerStore = useCustomerStore();
  const { regionHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
  };
  try {
    const customerId = customerStore.customer?.id;
    const jwt = getCookie(soloUserCookie);
    const body = JSON.stringify({
      jwt,
      customerId,
    });

    const data = await useFetchApiLayer(`/getAllOrders`, {
      method: 'POST',
      headers,
      body,
    });
    customerStore.setOrders(data);
  } catch (error: any) {
    useHandleCustomerError({ error });
    Promise.resolve(error);
  }
  customerStore.setLoading(false);
}

export async function useIsLoggedIn() {
  const customerStore = useCustomerStore();
  const config = useRuntimeConfig();
  const domain = config?.public?.cookieDomain;
  const { regionHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
  };

  const checkoutAction = getCookie('checkout_action');
  if (checkoutAction === 'logout') {
    deleteCookie('checkout_action', domain);
    return useLogout();
  }

  try {
    const jwt = getCookie(soloUserCookie);
    if (!jwt) {
      if (customerStore.customer) throw new CustomerException('You are not logged in.');
      return;
    }
    const res = await useFetchApiLayer(`/customerValidate`, {
      method: 'POST',
      headers,
      body: jwt,
    });
    if (!res || !res?.customer?.id) throw new CustomerException('Login Error. Please try again.', 'warning');

    customerStore.setLoggedIn(true);
    customerStore.setCustomer(res.customer);
    await useGetAllOrders();
    Promise.resolve(true);
  } catch (error: any) {
    useHandleCustomerError({ error });
    customerStore.setLoggedIn(false);
    deleteCookie(soloUserCookie, domain);
    Promise.resolve(error);
  }
}

export async function useHandlePasswordReset(data: any) {
  const customerStore = useCustomerStore();
  const { regionHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
  };
  try {
    const res = await useFetchApiLayer(`/handlePasswordReset/?email=${data.email}`, {
      method: 'GET',
      headers,
    });
    if (res?.data?.status) customerStore.setIsMailSent(true);
  } catch (error: any) {
    useHandleCustomerError({ error });
    Promise.resolve(error);
  }
}

export async function useUpdatePassword(data: any) {
  const customerStore = useCustomerStore();
  const { regionHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
  };
  try {
    const res = await useFetchApiLayer(`/updatePassword`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });
    if (res?.data?.status) customerStore.setIsPasswordUpdated(true);
  } catch (error: any) {
    useHandleCustomerError({ error });
    Promise.resolve(error);
  }
}

/* eslint n/handle-callback-err: ['warn', 'error'] */
/* eslint @typescript-eslint/no-unused-vars: 'warn' */
export function useHandleCustomerError({ error, level }: any) {
  const customerStore = useCustomerStore();
  const { $sentry } = useNuxtApp();
  const customerData = customerStore.customer;
  const customerEmail = customerData?.email;
  const geoStore = useGeodataStore();
  const geo = geoStore?.geo;
  const ip = geo?.ip;
  const user = ip || customerEmail ? { ip, customerEmail } : undefined;
  const tags = [{ 'customer-flow': 'account' }];
  const exception: any = { $sentry, error, user, tags, level };
  sentryScopedException(exception);
}
